<template>
    <el-dialog title="PDF预览" :visible.sync="dialogVisible" width="780px" custom-class="custom-preview-dialog"
        destroy-on-close top="2vh">
        <div class="flex justify-center pdf-buttons">
            <el-button icon="el-icon-arrow-left" :disabled="page === 1" circle @click="prevPageHandler"></el-button>
            <template v-if="index >= 0">
                <el-button type="primary" @click="prevPdfHandler" v-if="index >= 1">上一篇</el-button>
                <el-button type="primary" @click="nextPadHandler" v-if="index + 1 < pdfs.length">下一篇</el-button>
            </template>
            <el-button icon="el-icon-arrow-right" :disabled="page === numPages" circle @click="nextPageHandler"></el-button>
        </div>
        <div class="content" v-loading="loading" element-loading-text="PDF拼命加载中" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)" :key="url">
            <!-- <pdf ref="pdf" v-for="i in numPages" :key="i" :src="url" :page="i"></pdf> -->
            <pdf ref="pdf" :src="url" :page="page" :rotate="rotate" @progress="loadedRatio = $event" @error="error"
                @num-pages="numPages = $event" @link-clicked="page = $event" @page-loaded="pageLoadHandler"
                @loaded="loadHandler"></pdf>
        </div>
        <span slot="footer">
            <div class="flex justify-center  pdf-buttons" v-if="index >= 0">
                <el-button icon="el-icon-arrow-left" :disabled="page === 1" circle @click="prevPageHandler"></el-button>
                <el-button type="primary" @click="prevPdfHandler" v-if="index >= 1">上一篇</el-button>
                <el-button type="primary" @click="nextPadHandler" v-if="index + 1 < pdfs.length">下一篇</el-button>
                <el-button icon="el-icon-arrow-right" :disabled="page === numPages" circle
                    @click="nextPageHandler"></el-button>
            </div>
        </span>
    </el-dialog>
</template>

<script>
import pdf from 'vue-pdf-signature';
// import pdf from 'vue-pdf';
export default {
    components: {
        pdf
    },
    data() {
        return {
            dialogVisible: false,
            url: "",
            numPages: 0, // pdf 总页数
            pdfs: [],
            index: -1,
            loading: false,
            loadedRatio: 0,
            page: 1,
            rotate: 0,
        };
    },
    methods: {
        showHandler({ url, urls }) {
            console.log(url, urls);
            this.url = encodeURI(url).replace(/\+/g, '%2B');
            console.log(this.url);
            this.pdfs = urls;
            this.index = urls.findIndex(item => item.includes(url)) || 0;
            console.log(this.index);
            this.getNumPages();
            this.dialogVisible = true;
        },
        error(err) {
            console.error('pdf 加载失败', err);
            // this.$msg('pdf 加载失败')
        },
        getNumPages() {
            let loadingTask = pdf.createLoadingTask(this.url);
            loadingTask.promise.then(pdf => {
                console.log(pdf._pdfInfo, 'pdf._pdfInfo.numPages');
                this.$set(this, 'numPages', pdf._pdfInfo.numPages)
            }).catch(err => {
                console.error('pdf 加载失败', err);
                this.$msg('pdf 加载失败')
            }).finally(() => {
                this.loading = false;
            })
        },
        nextPadHandler() {
            this.$nextTick(() => {
                this.index += 1;
                this.$set(this, 'page', 1)
                this.$set(this, 'numPages', 0)
                this.$set(this, 'url', encodeURI(this.pdfs[this.index]).replace(/\+/g, '%2B'));
                this.getNumPages();
            })
            console.log(this.url, this.pdfs);
        },
        prevPdfHandler() {
            this.$nextTick(() => {
                this.index -= 1;
                this.$set(this, 'page', 1)
                this.$set(this, 'numPages', 0)
                this.$set(this, 'url', encodeURI(this.pdfs[this.index]).replace(/\+/g, '%2B'));
                this.getNumPages();
            })
        },
        prevPageHandler() {
            if (this.page > 0) {
                this.$set(this, 'page', this.page - 1)
            }
        },
        nextPageHandler() {
            if (this.page <= this.numPages) {
                this.$set(this, 'page', this.page + 1)
            }
        },
        pageLoadHandler(e) {
            console.log(e);
        },
        loadHandler(e) {
            console.log(e, 'loadHandler');
        }
    },

};
</script>

<style lang="scss">
.custom-preview-dialog {
    .el-dialog__header {
        text-align: center;
        font-size: 16px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #222222;
        line-height: 70px;
        padding: 0;
        border-bottom: 1px solid #E6E6E6;
    }

    .content {
        width: 100%;
        height: 1050px;
        overflow: auto;
    }

    // .el-dialog__footer {

    // }
    .pdf-buttons {
        .el-button--primary {
            background-color: #bb0e0f;
            border-color: #bb0e0f;
            width: 150px;
            height: 45px;
            line-height: 45px;
            background: #bb0e0f;
            border-radius: 7px 7px 7px 7px;
            opacity: 1;
            padding: 0;
            font-size: 14px;
            color: #fff;

            &:focus,
            &:hover {
                background-color: rgba(187, 14, 15, 0.8);
                color: #fff;
            }
        }
    }
}
</style>