<template>
    <div class='empty-wrap'>
        <img class="empty-image" :src="isApply ? require('@/assets/empty2.png') : imgUrl"
            :style="{ width: isApply ? '166px' : '110px' }" />
        <p>{{ text }}</p>
    </div>
</template>

<script>

export default {
    name: 'empty',
    props: {
        imgUrl: {
            type: String,
            default: require('@/assets/empty.png')
        },
        text: {
            type: String,
            default: '空空如也'
        },
        isApply: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    data() {
        return {}
    },
    created() { },
    mounted() { },
    methods: {},
    computed: {},
    watch: {},
}
</script>
<style lang='scss' scoped>
.empty-wrap {
    margin: auto;

    .empty-image {
        width: 110px;
        height: auto;
        display: block;
        margin: 0 auto 6px;
    }

    p {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
        text-align: center;
    }
}
</style>