<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
import { queryIndex } from '@/http/api'
import { mapActions } from 'vuex';

export default {
    name: 'App',
    mounted() {
        this.queryIndexHandler();
    },
    methods: {
        ...mapActions(['setIndexData']),
        queryIndexHandler() {
            queryIndex().then(res => {
                this.setIndexData(res || {});
                const { title, keywords, description, info = {} } = res || {}
                const { favicon_image, } = info;

                var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                link.type = 'image/x-icon';
                link.rel = 'shortcut icon';
                link.href = favicon_image;
                document.getElementsByTagName('head')[0].appendChild(link);
                document.title = title;

                if (title) {
                    document.title = title || '';
                }
                document
                    .querySelector('meta[name="keywords"]')
                    .setAttribute("content", keywords || '');
                document
                    .querySelector('meta[name="description"]')
                    .setAttribute("content", description || '');
                const ua = navigator.userAgent.toLowerCase();
                // console.log(ua,);
                if (ua.includes('hmscore')) {
                    // document
                    //     .querySelector('meta[name="viewport"]')
                    //     .setAttribute("content", 'width=device-width,initial-scale=0.3');
                } else {
                    document
                        .querySelector('meta[name="viewport"]')
                        .setAttribute("content", 'width=device-width,initial-scale=1');
                }
            })
        }
    },
}
</script>

<style>
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    padding: 0;
    margin: 0;
    width: 100%;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
</style>
