<template>
    <div class="form-item flex" :style="{ height: typeof height === 'number' ? `${height}px` : `${height}` }">
        <div class="label flex justify-center" v-if="!!tips">
            <el-tooltip class="item" effect="dark" :content="tips" placement="top-start">
                <div class="flex justify-center">
                    <span class="required" v-if="required">*</span>
                    <span class="label-name">{{ label }}</span>
                    <i class="el-icon-warning-outline"></i>
                </div>
            </el-tooltip>
        </div>
        <div class="label flex justify-center" v-else>
            <span class="required" v-if="required">*</span>
            <span class="label-name">{{ label }}</span>
        </div>
        <div class="form-content flex justify-center">
            <div class="type-wrap">
                <slot class="slot-content"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {
    },
    name: 'form-item',
    props: {
        required: {
            type: Boolean,
            default: true
        },
        label: {
            type: String,
            default: ''
        },
        tips: {
            type: String,
            default: ''
        },
        error: {
            type: String,
            default: ''
        },
        height: {
            type: [Number, String],
            default: 52
        },
        exTip: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            showErrorTooltip: false
        }
    },
    mounted() { },
    computed: {
        options() {
            return {
                content: this.error,
                placement: 'top-start',
                show: this.showErrorTooltip,
                offset: '-40px',
                trigger: 'manual',
                container: '.el-icon-error',
            }
        }
    },
    watch: {
        error: {
            handler(v) {
                this.$set(this, 'showErrorTooltip', !!v);
            },
            immediate: true
        }
    }
}
</script>

<style lang="scss">
.form-item {
    border-style: solid;
    border-width: 1px 1px 0px 1px;
    border-color: #222;
    overflow: hidden;
    width: 100%;

    .label {
        width: 158px;
        height: 100%;
        flex-shrink: 0;

        .label-name {
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            margin: 0 3px;
        }

        .required {
            color: #BB0E0F;
        }

        .el-icon-warning-outline {
            color: #E57630;
        }
    }

    .form-content {
        padding: 0 20px;
        height: 100%;
        flex: 1;
        overflow: hidden;
        border-style: solid;
        border-width: 0px 0 0 1px;
        border-color: #222;

        .text-wrap {
            padding: 10px 0;
            width: 100%;
            word-break: break-all;
            word-wrap: break-word;
            font-size: 14px;
        }

        .el-icon-error {
            color: #E53030;
            margin-left: 10px;
        }

        .type-wrap {
            flex: 1;
            min-height: 35px;

            .el-form-item {
                margin-bottom: 0;

                .el-form-item__error {
                    top: 75%;
                }

                .material-wrap {
                    &+.el-form-item__error {
                        top: 92%;
                    }
                }
            }

            .el-input {
                width: 100%;

                .el-input__inner {
                    border: none;
                    padding: 0;
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #222222;
                }

                &.is-disabled {

                    .el-input__inner {
                        background-color: #fff;
                    }
                }

                &.el-date-editor {
                    cursor: pointer;

                    .el-input__prefix {
                        display: none;
                    }

                    .el-input__inner {
                        text-align: center;
                    }
                }
            }

            .el-textarea {
                .el-textarea__inner {
                    border: none;
                    min-height: 120px !important;
                }

                &.is-disabled {

                    .el-textarea__inner {
                        background-color: #fff;
                        color: #222;
                    }
                }
            }

            .el-radio-group {
                .el-radio {
                    margin: 5px 30px 5px 0;

                    &.is-checked {}

                    .el-radio__input {
                        &.is-checked {
                            .el-radio__inner {
                                background-color: #E53030;
                                border-color: #E53030;
                            }

                            &+.el-radio__label {
                                color: #222;
                            }
                        }

                    }
                }
            }

            .el-checkbox-group {
                .el-checkbox {
                    &.is-checked {}

                    .el-checkbox__input {
                        &.is-checked {
                            .el-checkbox__inner {
                                background-color: #E53030;
                                border-color: #E53030;
                            }

                            &+.el-checkbox__label {
                                color: #222;
                            }
                        }

                    }
                }
            }

            .el-cascader {
                width: 100%;
            }
        }

        .bottom-tips {
            margin: 5px 0 2px;
            color: blue;
            font-size: 14px;
            line-height: 1;
            padding-left: 5px;
        }
    }

    &.error-100 {
        .type-wrap {

            .el-form-item {

                .el-form-item__error {
                    top: 100%;
                }
            }
        }
    }
}
</style>
