<template>
    <ul class="preview-image flex flex-wrap">
        <li class="" v-for="(item, index) in list" :key="index">
            <el-image :src="item.fullurl" :preview-src-list="previewUrl"
                :initial-index="initialIndex(item.fullurl, index)"></el-image>
            <i class="el-icon-circle-close" v-if="!readonly" @click.stop="deleteHandler(index)"></i>
        </li>
    </ul>
</template>

<script>

export default {
    name: 'file-image-list',
    components: {},
    data() {
        return {}
    },
    props: {
        list: {
            type: Array,
            default: () => []
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    created() { },
    mounted() { },
    methods: {
        deleteHandler(index) {
            this.$emit('delete', index);
        },

        initialIndex(url, index) {
            if (!!this.images && !!this.images() && Array.isArray(this.images())) {
                return this.images().findIndex(item => item === url);
            } else {
                return index
            }
        }
    },
    computed: {
        previewUrl({ list }) {
            if (!!this.images && !!this.images() && Array.isArray(this.images())) {
                return this.images();
            } else {
                return list.map(item => item.fullurl);
            }
        },
    },
    watch: {},
    inject: {
        images: { default: () => { } }
    }
}
</script>
<style lang='scss' scoped></style>