import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const state = {
	userInfo: {},
	token: undefined,
	indexData: {},
};

const mutations = {
	SET_USER_INFO: (state, payload) => {
		state.userInfo = payload;
	},
	SET_TOKEN: (state, payload) => {
		state.token = payload;
	},
	SET_INDEX_DATA: (state, payload) => {
		state.indexData = payload;
	},
};

const actions = {
	setUserInfo({ commit }, data) {
		commit("SET_USER_INFO", data);
	},
	setToken({ commit }, data) {
		if (data) {
			commit("SET_TOKEN", data.token);
			commit("SET_USER_INFO", data);
		} else {
			commit("SET_TOKEN", undefined);
		}
	},
	setIndexData({ commit }, data) {
		commit("SET_INDEX_DATA", data);
	},
};

const getters = {
	userInfo: state => state.userInfo,
	token: state => state.token,
	indexData: state => state.indexData,
};

const store = new Vuex.Store({
	plugins: [
		createPersistedState({
			key: "topTen",
		}),
	],
	state,
	mutations,
	actions,
	getters,
});

export default store;
