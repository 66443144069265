<template>
    <el-upload class="upload-file-component" action="" :accept="accept" :multiple="multiple" :limit="limit"
        :show-file-list="false" :on-exceed="handleExceed" :on-change="handleChange" :on-success="handleSuccess"
        :http-request="handleHttpRequest" :before-upload="beforeUpload" :file-list="fileList">
        <el-button>{{ uploadText }}</el-button>
    </el-upload>
</template>

<script>
import { mapGetters } from 'vuex';
import { uploadNotify, queryStsToken } from "@/http/api";
import OSS from "ali-oss"
const md5 = require("md5")
import { PDFDocument, PDFDocumentLoadingTask } from 'pdf-lib';
export default {
    props: {
        value: {
            type: Array,
            default: () => []
        },
        limit: {
            type: Number,
            default: 1
        },
        multiple: {
            type: Boolean,
            default: true
        },
        accept: {
            type: String,
            default: '.pdf'
        },
        text: {
            type: String,
            default: ''
        }
    },
    components: {},
    data() {
        return {
            fileList: [],
            action: this.$baseUrl + '/api/common/upload'
        }
    },
    created() { },
    mounted() { },
    methods: {
        beforeUpload(file) {
            const isPdf = file.type.includes('pdf') || file.type.includes('PDF');
            if (isPdf) {
                const fileSizeMb = file.size / 1024 / 1024;
                if (fileSizeMb > 30) {
                    this.$msg('上传文件应小于30MB!', 200, 5000);
                    return false;
                }
                return new Promise((resolve, reject) => {
                    this.getFileArrayBuffer(file).then(arrayBuffer => {
                        this.checkIfPdfIsEncrypted(arrayBuffer).then(resolve).catch(reject)
                    })
                });
            } else {
                this.$msg('请上传PDF扫描件!', 200, 5000);
                return false;
            }
        },
        getFileArrayBuffer(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (event) => {
                    resolve(event.target.result);
                };
                reader.onerror = (event) => {
                    reject(event.target.error);
                };
                reader.readAsArrayBuffer(file);
            });
        },

        async checkIfPdfIsEncrypted(arrayBuffer) {
            return new Promise((resolve, reject)=>{
                try {
                    PDFDocument.load(arrayBuffer, { ignoreEncryption: true }).then(pdfDoc =>{
                        if(pdfDoc.isEncrypted){
                            this.$msg('不允许上传加密的PDF文件!', 200, 5000);
                            reject()
                        }
                        resolve()
                    })
                } catch (error) {
                    console.error('Error loading PDF or checking encryption:', error);
                    reject()
                }
            })
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        handleExceed(files, fileList) {
          this.$msg(`最多上传 ${this.limit} 个文件`, 200, 5000)
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        async uploadInit(file) {
            const { name, size, type } = file;
            return new Promise((resolve, reject) => {
                queryStsToken({
                    name, size, type
                }).then(res => {
                    resolve(res)
                })
            })
        },
        handleHttpRequest(data) {
            const { file } = data;

            const type = file.type.slice(file.type.indexOf('/') + 1);

            this.uploadInit(file).then(ossParams => {
                console.log(ossParams, 'ossParams');
                if (ossParams.aliosstoken) {
                    if (!ossParams.mimetype.includes(type)) {
                        this.$message(`请上传${ossParams.mimetype}类型文件`);
                        return false;
                    }
                    const ossClient = new OSS({
                        accessKeyId: ossParams.accessKeyId,
                        accessKeySecret: ossParams.accessKeySecret,
                        stsToken: ossParams.securityToken,
                        bucket: ossParams.bucket,
                        endpoint: ossParams.endpoint,
                    });

                    ossClient
                        .multipartUpload(ossParams.file, file, {
                            progress: function (a, b) {
                                data.onProgress({
                                    percent: (a || 0) * 100 || 0,
                                })
                            },
                        })
                        .then(result => {
                            const url = `${document.location.protocol}//${ossParams.bucket}.${ossParams.endpoint}${result.name}`
                            // const url = `https://${ossParams.bucket}.${ossParams.endpoint}${result.name}`
                            this.uploadCallback(data, url, ossParams)
                        })
                } else if (ossParams.url && ossParams.fullurl) {
                    // 重复文件，直接返回地址
                    data.onSuccess({ name: file.name, url: ossParams.url, fullurl: ossParams.fullurl })
                } else {
                    data.onError()
                }
            })
        },
        uploadCallback(target, fullurl, ossParams) {

            const { file } = target;

            uploadNotify({
                aliosstoken: ossParams.aliosstoken,
                size: file.size,
                name: file.name,
                md5: md5(file.name),
                url: ossParams.file,
                type: file.type,
                width: 0,
                height: 0
            })
                .then(res => {
                    target.onSuccess({ name: file.name, url: ossParams.file, fullurl })
                })
                .catch(err => {
                    console.log(err, "upload cb error")
                    target.onError()
                })
        },
        handleChange(file, fileList) {
            // console.log(file, fileList);

        },
        handleSuccess(response, file, fileList) {
            console.log(response, file, fileList, this.fileList);
            this.$emit('input', fileList.map(item => {
                if (Object.keys(item.response || {}).length > 0 && item.response.url) {
                    return item.response
                } else {
                    return item;
                }
            }))
        }
    },
    computed: {
        ...mapGetters(['token']),
        uploadText({ text, limit }) {
            if (!!text) {
                return text;
            } else {
                return `上传${limit === 1 ? '' : '多'}文件`
            }
        }
    },
    watch: {
        value: {
            handler(newVal) {
                this.$set(this, 'fileList', newVal);
            },
            immediate: true
        }
    }
}
</script>
<style lang='scss'>
.upload-file-component {
    // width: 100%;
}
</style>