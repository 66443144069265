import Vue from "vue";
import Router from "vue-router";
//引入在路由中需要用到的组件

Vue.use(Router); //加载全局组件Router

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
};

export default new Router({
	mode: "history", //路由模式:默认为hash,如果改为history,则需要后端进行配合
	base: "web",
	routes: [
		{
			path: "/",
			component: resolve => require(["@/views/layout/index"], resolve),
			redirect: "/home",
			children: [
				{
					path: "/home",
					name: "home",
					component: resolve => require(["@/views/index"], resolve),
				},
				{
					path: "/login",
					name: "login",
					component: resolve => require(["@/views/login"], resolve),
				},
				{
					path: "/register",
					name: "register",
					component: resolve =>
						require(["@/views/register"], resolve),
				},
				{
					path: "/changePwd",
					name: "changePwd",
					component: resolve =>
						require(["@/views/changePwd"], resolve),
				},
				{
					path: "/introduce",
					name: "introduce",
					component: resolve =>
						require(["@/views/introduce/index"], resolve),
				},
				{
					path: "/activity",
					name: "activity",
					component: resolve =>
						require(["@/views/activity/index"], resolve),
				},
				{
					path: "/exhibition",
					name: "exhibition",
					component: resolve =>
						require(["@/views/exhibition/index"], resolve),
				},
				{
					path: "/news",
					name: "news",
					component: resolve =>
						require(["@/views/news/index"], resolve),
					redirect: "/news/list",
					children: [
						{
							path: "/newsList",
							name: "newsList",
							component: resolve =>
								require(["@/views/news/list"], resolve),
						},
						{
							path: "/detail",
							name: "newsDetail",
							component: resolve =>
								require(["@/views/news/detail"], resolve),
						},
					],
				},
				{
					path: "/apply",
					name: "apply",
					component: resolve =>
						require(["@/views/apply/index"], resolve),
					redirect: "/apply/system",
					children: [
						// {
						// 	path: "/system",
						// 	name: "system",
						// 	component: resolve =>
						// 		require(["@/views/apply/system"], resolve),
						// },
						// {
						// 	path: "/guide",
						// 	name: "guide",
						// 	component: resolve =>
						// 		require(["@/views/apply/guide"], resolve),
						// },
						{
							path: "/information",
							name: "information",
							component: resolve =>
								require(["@/views/apply/information"], resolve),
						},
						{
							path: "/grade",
							name: "grade",
							component: resolve =>
								require(["@/views/apply/grade"], resolve),
						},
						{
							path: "/condition",
							name: "condition",
							component: resolve =>
								require([
									"@/views/apply/grade/condition",
								], resolve),
						},
						{
							path: "/gradeInfo",
							name: "gradeInfo",
							component: resolve =>
								require(["@/views/apply/grade/info"], resolve),
						},
						{
							path: "/gxjGradeInfo",
							name: "gxjGradeInfo",
							component: resolve =>
								require(["@/views/apply/grade/gxj"], resolve),
						},
						{
							path: "/gradeInformation",
							name: "gradeInformation",
							component: resolve =>
								require([
									"@/views/apply/grade/information",
								], resolve),
						},
						{
							path: "/vote",
							name: "vote",
							component: resolve =>
								require(["@/views/apply/vote"], resolve),
						},
						{
							path: "/manage",
							name: "manage",
							component: resolve =>
								require([
									"@/views/apply/manage/index",
								], resolve),
						},
						{
							path: "/manageForm",
							name: "manageForm",
							component: resolve =>
								require(["@/views/apply/manage/form"], resolve),
						},
						{
							path: "/manageGxj",
							name: "manageGxj",
							component: resolve =>
								require(["@/views/apply/manage/gxj"], resolve),
						},
						{
							path: "/download",
							name: "download",
							component: resolve =>
								require([
									"@/views/apply/manage/form/test",
								], resolve),
						},
						{
							path: "/downloadGxj",
							name: "downloadGxj",
							component: resolve =>
								require([
									"@/views/apply/manage/gxj/download",
								], resolve),
						},
					],
				},
				{
					path: "/activityDetail",
					name: "activityDetail",
					component: resolve =>
						require(["@/views/activity/detail"], resolve),
				},
			],
		},
		{
			path: "*",
			redirect: "/",
		},
	],
});
