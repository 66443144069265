// https://www.apifox.cn/apidoc/shared-ec546dcf-7243-40bb-979c-80635cb50876
import request from "@/http/request";

// import qs from "qs"

export function queryIndex() {
	return request({
		url: "/index",
	});
}

export function queryProject() {
	return request({
		url: "/project/list",
	});
}

export function login(data) {
	return request({
		url: "/user/login",
		method: "post",
		data,
	});
}

export function register(data) {
	return request({
		url: "/user/register",
		method: "post",
		data,
	});
}

export function logout(data) {
	return request({
		url: "/user/logout",
		method: "post",
		data,
	});
}

export function changePwd(data) {
	return request({
		url: "/user/editpwd",
		method: "post",
		data,
	});
}

export function resetPwd(data) {
	return request({
		url: "/user/resetpwd",
		method: "post",
		data,
	});
}

export function smsSendCode(data) {
	return request({
		url: "/sms/send",
		method: "post",
		data,
	});
}

export function getUserInfo(data = {}) {
	return request({
		url: "/user/index",
		params: data,
	});
}

export function querySpreadInfo(data) {
	return request({
		url: `/spread/info`,
		params: data,
	});
}

export function querySpreadCategory(data) {
	return request({
		url: `/spread/category`,
	});
}

export function querySpreadList(data) {
	return request({
		url: `/spread/list`,
		params: data,
	});
}

export function queryNewsCate() {
	return request({
		url: `/news/category`,
	});
}

export function queryNewsList(data) {
	return request({
		url: `/news/list`,
		params: data,
	});
}

export function queryNewsInfo(data) {
	return request({
		url: `/news/info`,
		params: data,
	});
}

export function queryExhibitInfo(data) {
	return request({
		url: `/exhibit/info`,
		params: data,
	});
}

export function queryExhibitCate() {
	return request({
		url: `/exhibit/category`,
	});
}

export function queryExhibitList(data) {
	return request({
		url: `/exhibit/list`,
		params: data,
	});
}

export function queryApplyCate(data) {
	return request({
		url: `/apply/category`,
		params: data,
	});
}

export function queryApplyTerritory(data) {
	return request({
		url: `/apply/territory`,
		params: data,
	});
}

export function queryMaterial(data) {
	return request({
		url: `/field_data/material`,
		params: data,
	});
}

export function queryApplyPlan() {
	return request({
		url: `/apply/plan`,
	});
}

export function queryApplyList(data) {
	return request({
		url: `/apply/list`,
		params: data,
	});
}

export function queryApplyInfo(data) {
	return request({
		url: `/apply/info`,
		params: data,
	});
}

export function queryField(data) {
	return request({
		url: `/field_data/list`,
		params: data,
	});
}

export function queryFieldAll(data) {
	return request({
		url: `/field_data/all`,
		params: data,
	});
}

export function queryMessage(data) {
	return request({
		url: `/message/list`,
		params: data,
	});
}

export function queryArea(data) {
	return request({
		url: `/area/area`,
		params: data,
	});
}

export function queryAreaAll(data) {
	return request({
		url: `/area/all`,
		params: data,
	});
}

export function applySubmit1(data) {
	return request({
		url: `/apply/step1`,
		method: "post",
		data,
	});
}
export function applySubmit2(data) {
	return request({
		url: `/apply/step2`,
		method: "post",
		data,
	});
}
export function applySubmit3(data) {
	return request({
		url: `/apply/step3`,
		method: "post",
		data,
	});
}
export function applySubmit4(data) {
	return request({
		url: `/apply/step4`,
		method: "post",
		data,
	});
}

export function uploadFile(data) {
	return request({
		url: "/common/upload",
		method: "post",
		data,
		contentType: "multipart/form-data",
	});
}

export function queryGradeList(data) {
	return request({
		url: `/grade/list`,
		params: data,
	});
}

export function queryGradePlan(data) {
	return request({
		url: `/grade/plan`,
		params: data,
	});
}

export function queryGradeConfig(data) {
	return request({
		url: `/grade/config`,
		params: data,
	});
}

export function queryGradeInfo(data) {
	return request({
		url: `/grade/info`,
		params: data,
	});
}

export function gradeSubmit(data) {
	return request({
		url: `/grade/submit`,
		method: "post",
		data,
	});
}

export function expressSubmit(data) {
	return request({
		url: `/express/submit`,
		method: "post",
		data,
	});
}

export function expressEdit(data) {
	return request({
		url: `/express/edit`,
		method: "post",
		data,
	});
}

export function queryExpressList(data) {
	return request({
		url: `/express/list`,
		params: data,
	});
}

export function queryExpressInfo(data) {
	return request({
		url: `/express/info`,
		params: data,
	});
}

export function declarationSubmit(data) {
	return request({
		url: `/declaration/submit`,
		method: "post",
		data,
	});
}

export function cancelApply(data) {
	return request({
		url: `/apply/cancel`,
		params: data,
	});
}

export function deleteApply(data) {
	return request({
		url: `/apply/delete`,
		params: data,
	});
}

export function downloadFiles2Zip(data) {
	return request({
		url: `/apply/downfiles`,
		params: data,
	});
}
export function readAll(data) {
	return request({
		url: `/message/readAll`,
		params: data,
	});
}

export function messageNumber() {
	return request({
		url: `/message/number`,
	});
}

export function messageInfo(data) {
	return request({
		url: `/message/info`,
		params: data,
	});
}

export function forceMessage() {
	return request({
		url: `/message/forceMessage`,
	});
}

export function getUserInfoByToken(data) {
	return request({
		url: "/user/member",
		params: data,
	});
}

export function uploadNotify(data) {
	return request({
		url: `/oss/notify`,
		method: "post",
		data,
	});
}

export function queryStsToken(data) {
	return request({
		url: `/oss/stsparams`,
		method: "post",
		data,
	});
}

export function lastApply() {
	return request({
		url: `/apply/lastApply`,
	});
}

export function queryVotePlan() {
	return request({
		url: `/vote/plan`,
	});
}

export function queryVoteList(data) {
	return request({
		url: `/vote/list`,
		params: data,
	});
}

export function queryVoteInfo(data) {
	return request({
		url: `/vote/info`,
		params: data,
	});
}

export function submitVote(data) {
	return request({
		url: `/vote/submit`,
		method: "post",
		data,
	});
}
