<template>
    <el-upload
        class="upload-image-component"
        :action="action"
        :accept="accept"
        :multiple="limit > 1"
        :limit="limit"
        :on-success="handleSuccess"
        ref="uploadImage"
        :show-file-list="false"
        :on-exceed="handleExceed"
        :on-change="handleChange"
        :http-request="handleHttpRequest"
        :before-upload="beforeUpload"
        :file-list="fileList"
    >
        <i slot="default" class="el-icon-plus"></i>
        <!-- <i slot="default" class="el-icon-plus" v-if="!singleUrl"></i> -->
        <!-- <el-image :src="singleUrl" v-else :preview-src-list="[singleUrl]"></el-image> -->
    </el-upload>
</template>

<script>
import { mapGetters } from "vuex";
import { uploadNotify, queryStsToken } from "@/http/api";
import OSS from "ali-oss";
const md5 = require("md5");

export default {
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        limit: {
            type: Number,
            default: 1,
        },
        accept: {
            type: String,
            default: ".png,.jpg,.jpeg,.gif",
        },
        text: {
            type: String,
            default: "",
        },
        size: {
            type: Array,
            default: () => [],
        },
    },
    components: {},
    data() {
        return {
            fileList: [],
            action: this.$baseUrl + "/api/common/upload",
            singleUrl: "",
        };
    },
    created() {},
    mounted() {},
    methods: {
        beforeUpload(file) {
            const fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
            if (this.accept.includes(fileType)) {
                const fileSizeMb = file.size / 1024 / 1024;
                if (fileSizeMb > 10) {
                    this.$msg("上传文件应小于10MB!", 200, 5000);
                    return false;
                }
                if (this.size.length === 2) {
                    if (fileSizeMb >= this.size[1]) {
                        this.$msg(`上传图片大小需${this.size[1]}MB以下!`);
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            } else {
                this.$msg(`请上传${this.accept}格式文件!`);
                return false;
            }
        },

        handleExceed(files, fileList) {
            this.$msg(`最多上传 ${this.limit} 个文件`, 200, 5000);
        },
        async uploadInit(file) {
            const { name, size, type } = file;
            return new Promise((resolve, reject) => {
                queryStsToken({
                    name,
                    size,
                    type,
                }).then((res) => {
                    resolve(res);
                });
            });
        },
        handleHttpRequest(data) {
            const { file } = data;

            const fileSizeMb = file.size / 1024 / 1024;

            const type = file.type.slice(file.type.indexOf("/") + 1);

            this.uploadInit(file).then((ossParams) => {
                console.log(ossParams, "ossParams");
                if (ossParams.aliosstoken) {
                    if (!ossParams.mimetype.includes(type)) {
                        this.$message(`请上传${ossParams.mimetype}类型文件`);
                        return false;
                    }
                    const ossClient = new OSS({
                        accessKeyId: ossParams.accessKeyId,
                        accessKeySecret: ossParams.accessKeySecret,
                        stsToken: ossParams.securityToken,
                        bucket: ossParams.bucket,
                        endpoint: ossParams.endpoint,
                    });

                    ossClient
                        .multipartUpload(ossParams.file, file, {
                            progress: function (a, b) {
                                data.onProgress({
                                    percent: (a || 0) * 100 || 0,
                                });
                            },
                        })
                        .then((result) => {
                            const url = window.location.protocol + `//${ossParams.bucket}.${ossParams.endpoint}${result.name}`;
                            console.log(url);
                            // const url = `https://${ossParams.bucket}.${ossParams.endpoint}${result.name}`
                            this.uploadCallback(data, url, ossParams);
                        });
                } else if (ossParams.url && ossParams.fullurl) {
                    // 重复文件，直接返回地址
                    data.onSuccess({ name: file.name, url: ossParams.url, fullurl: ossParams.fullurl });
                } else {
                    data.onError();
                }
            });
        },
        uploadCallback(target, fullurl, ossParams) {
            const { file } = target;

            uploadNotify({
                aliosstoken: ossParams.aliosstoken,
                size: file.size,
                name: file.name,
                md5: md5(file.name),
                url: ossParams.file,
                type: file.type,
                width: 0,
                height: 0,
            })
                .then((res) => {
                    target.onSuccess({ name: file.name, url: ossParams.file, fullurl });
                })
                .catch((err) => {
                    console.log(err, "upload cb error");
                    target.onError();
                });
        },
        handleChange(file, fileList) {
            console.log(file, fileList);
        },
        handleSuccess(response, file, fileList) {
            console.log(response, file, fileList, this.fileList);
            this.$emit(
                "input",
                fileList.map((item) => {
                    if (Object.keys(item.response || {}).length > 0 && item.response.url) {
                        return item.response;
                    } else {
                        return item;
                    }
                })
            );
        },
        // handleSuccess(response, file, fileList) {
        //     console.log(response, file, fileList,);
        //     if (this.limit === 1) {
        //         this.singleUrl = response.fullurl;
        //         this.$emit('input', [response]);
        //     } else {
        //         const fileLists = fileList.filter(item => ['success', 'fail'].includes(item.status));
        //         if (fileLists.length === fileList.length) {
        //             this.$emit('input', fileList.map(item => item.response))
        //         }
        //     }
        // },
    },
    computed: {
        ...mapGetters(["token"]),
        uploadText({ text, limit }) {
            if (!!text) {
                return text;
            } else {
                return `上传${limit === 1 ? "" : "多"}文件`;
            }
        },
    },
    watch: {
        value: {
            handler(newVal) {
                if (!newVal.length) {
                    this.singleUrl = "";
                }
                console.log(newVal, "newVal");
                this.$set(this, "fileList", newVal);
            },
            immediate: true,
        },
    },
};
</script>
<style lang="scss">
.upload-image-component {
    width: 80px;
    height: 80px;
    border-radius: 0;
    border: 1px dashed #666666;
    line-height: 78px;
    text-align: center;

    .el-icon-plus {
        font-size: 17px;
    }

    .el-image {
        width: 80px;
        height: 80px;
    }
}
</style>
