<template>
    <div class='file-list-wrap' :class="{ readonly: readonly }">
        <div class="file-list flex" v-for="(item, index) in list" :key="index">
            <p class="link flex" @click="previewHandler(item, index)" :title="item.url"><span class="ellipsis-1">{{ item.url
            }}</span><i class="el-icon-view"></i>
            </p>
            <template v-if="!readonly">
                <i class="el-icon-circle-check"></i>
                <i class="el-icon-close" @click="deleteHandler(index)"></i>
            </template>
        </div>

        <dialog-preview ref="pdfPreviewRef"></dialog-preview>
    </div>
</template>

<script>
import dialogPreview from './dialog-preview.vue'
export default {
    props: {
        list: {
            type: Array,
            default: () => []
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    components: {
        dialogPreview
    },
    data() {
        return {}
    },
    created() { },
    mounted() {
    },
    methods: {
        previewHandler({ url, fullurl }, index) {
            if (url.includes('.pdf')) {
                if (!!this.pdfs && !!this.pdfs() && Array.isArray(this.pdfs())) {
                    const pdfs = this.pdfs();
                    this.$refs.pdfPreviewRef.showHandler({ url: fullurl, urls: pdfs })
                } else {
                    this.$refs.pdfPreviewRef.showHandler({ url: fullurl, urls: [fullurl] })
                }
            }
        },
        deleteHandler(index) {
            this.$emit('delete', index);
        }
    },
    computed: {},
    watch: {},
    inject: {
        pdfs: {
            default: () => { }
        }
    }
}
</script>
<style lang='scss'>
.file-list-wrap {
    width: 100%;

    .file-list {
        width: 100%;
        height: 50px;
        border-top: 1px solid #222;

        &:last-of-type {
            border-bottom: none;
        }

        .link {
            width: 90%;
            text-align: right;
            padding-left: 5px;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #222222;
            cursor: pointer;
            margin-right: 10px;
            overflow: hidden;

            span {
                display: block;
                width: 100%;
            }

            i {
                margin-left: 10px;
            }
        }

        i {
            font-size: 14px;

            &.el-icon-circle-check {
                color: #67c23a;
            }

            &.el-icon-close {
                color: #bb0e0f;
                display: none;
            }
        }

        &:hover {
            .link {
                p {
                    text-decoration: underline;
                }
            }

            i {
                font-size: 14px;

                &.el-icon-circle-check {
                    display: none;
                }

                &.el-icon-close {
                    color: #bb0e0f;
                    display: block;
                    cursor: pointer;
                }
            }
        }
    }

    &.readonly {
        .file-list {
            &:first-of-type {
                border-top: none;
            }
        }
    }
}
</style>