import Vue from "vue";
import App from "./App.vue";

import store from "./store";
import router from "./router";

import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import "./style/common.scss";

Vue.config.productionTip = false;

Vue.use(Element, {
	size: "small",
});

function isMobile() {
	let flag =
		/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent
		);
	return flag;
}

Vue.prototype.isMobile = isMobile;

import "video.js/dist/video-js.css";

// 创建事件总线   就相当于创建了一个新的vue实例
const bus = new Vue();
// 把bus挂载到了Vue的原型上, 保证所有的组件都能通过 this.$bus访问到事件总线
Vue.prototype.$bus = bus;

import Empty from "@/components/empty";
import UploadFile from "@/components/upload-file";
import UploadImage from "@/components/upload-image";
import FileList from "@/components/file-list";
import FileImageList from "@/components/file-image-list";
import FormItem from "@/components/form-item.vue";

Vue.component("form-item", FormItem);
Vue.component("empty", Empty);
Vue.component("upload-file", UploadFile);
Vue.component("upload-image", UploadImage);
Vue.component("file-list", FileList);
Vue.component("file-image-list", FileImageList);

Vue.prototype.$baseUrl = process.env.VUE_APP_BASE_URL;

router.afterEach((to, from, next) => {
	window.scrollTo(0, 0);
});

const app = new Vue({
	router,
	store,
	render: h => h(App),
}).$mount("#app");

Vue.prototype.$msg = function (
	message,
	offset = 20,
	duration = 3000,
	type = "warning"
) {
	app.$notify({
		title: '提示',
		message: message,
		type: type,
		offset: offset,
		duration: duration
	});
};
