import axios from "axios";
import { Notification } from "element-ui";
import store from "@/store";
import router from "@/router";

const service = axios.create({
	baseURL: "/api",
	timeout: 120 * 1000,
});
let loadingInstance;
// request interceptor
service.interceptors.request.use(
	config => {
		config.headers["Content-Type"] = "multipart/form-data";

		if (config.method === "post") {
			config.headers["Content-Type"] = "application/json";
		}

		if (config.contentType) {
			config.headers["Content-Type"] = config.contentType;
		}

		const { token } = store.getters;
		if (!!token) {
			config.headers.token = token;
		}
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

// response interceptor
service.interceptors.response.use(
	response => {
		const { msg, code, data } = response.data;
		if (loadingInstance) {
			loadingInstance.close();
		}
		if (code == 1) {
			return data;
		} else {
			Notification({
				title: '提示',
				message: msg || "网络错误",
				type: "error",
				offset: 200,
				duration: 3000,
			});

			return Promise.reject(new Error(msg || "Error"));
		}
	},
	error => {
		console.log("err" + error); // for debug
		if (loadingInstance) {
			loadingInstance.close();
		}
		if (error.response) {
			const { status } = error.response;
			if (status === 401) {
				store.dispatch("setToken", undefined);
				Notification({
					title: '提示',
					message: "请先登录",
					type: "error",
					offset: 200,
					duration: 3000,
				});
				router.replace({
					name: "login",
				});
			} else {
				Notification({
					title: '提示',
					message: error.message,
					type: "error",
					offset: 200,
					duration: 3000,
				});
			}
		}
		return Promise.reject(error);
	}
);

export default service;
